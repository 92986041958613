<template>
  <div class="project-configuration-container">
    <div>
      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('general.sectionTitle') }}
          </h2>
          <p class="subtitle">
            {{ $t('general.sectionSubtitle') }}
          </p>
        </header>

        <div class="two-column-section__content">
          <div
            v-if="loading"
            class="d-flex flex-column sk-block pa-4 space-y-7">
            <div class="d-flex space-x-4">
              <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
              <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
            </div>
            <div class="sk-block sk-dark sk-h-16 sk-full-width"/>
            <div class="sk-block sk-h-8 sk-dark sk-w-24"/>
          </div>

          <v-card
            v-else
            data-cy="general-info-card"
            outlined
            class="pa-4 space-y-2">
            <div class="d-flex space-x-4">
              <v-text-field
                v-model.trim="$v.formData.name.$model"
                class="required-field"
                data-cy="name-input"
                :label="$t('forms.fieldName')"
                :error-messages="nameErrors"
                item-value="projectName"
                required
                @blur="$v.formData.name.$touch()"/>
              <v-text-field
                class="required-field"
                data-cy="canonical-input"
                :value="projectCanonical"
                :label="$t('untranslated.canonical')"
                :error-messages="canonicalErrors"
                disabled
                readonly
                required>
                <template #append>
                  <CyCopyBtn
                    :copy-value="projectCanonical"/>
                </template>
              </v-text-field>
            </div>
            <v-textarea
              v-model="$v.formData.description.$model"
              class="my-2"
              data-cy="description-input"
              :value="project.description"
              :label="$t('forms.fieldDescription')"
              :hint="$t('general.descriptionHint')"
              persistent-hint
              auto-grow/>
            <CyButton
              type="submit"
              data-cy="submit-btn"
              variant="primary"
              icon="done"
              :loading="saving"
              :disabled="!canSave"
              @click.native="updateProject">
              {{ $t('forms.btnSave') }}
            </CyButton>
          </v-card>

          <h2 class="content-title mt-6 mb-2 font-size-base font-weight-normal">
            {{ $t('Stack') }}
          </h2>

          <div
            v-if="loadingStack"
            class="d-flex flex-column sk-block pa-4 space-y-7">
            <div class="d-flex space-x-4">
              <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
              <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
              <div class="sk-block sk-h-8 sk-dark sk-w-24"/>
            </div>
            <div class="sk-block sk-dark sk-h-6 sk-full-width"/>
          </div>

          <CyWizardServiceCard
            v-else
            show-details-btn
            :details-btn-action="() => { previewedStack = stack }"
            :service="stack"/>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('configRepository.sectionTitle') }}
          </h2>
          <p class="subtitle">
            {{ $t('configRepository.sectionSubtitle') }}
          </p>
        </header>

        <div class="two-column-section__content">
          <h2 class="content-title mb-2 font-size-base font-weight-normal">
            {{ $t('ConfigRepository') }}
          </h2>

          <div
            v-if="loading"
            class="d-flex flex-column sk-block pa-4 space-y-7">
            <div class="d-flex space-x-4">
              <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
              <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
              <div class="sk-block sk-h-8 sk-dark sk-w-24"/>
            </div>
            <div class="sk-block sk-dark sk-h-6 sk-full-width"/>
          </div>

          <CyConfigRepositoryCard
            v-else
            class="mb-2"
            :config-repository="configRepository"/>
          <div
            v-has-rights-to="['UpdateProject', projectCanonical]"
            class="cy-link"
            @click="$toggle.modals.updateConfigRepository(true)">
            {{ $t('configRepository.edit') }}...
          </div>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section class="two-column-section">
        <header class="two-column-section__header">
          <h2 class="h5">
            {{ $t('ownership.sectionTitle') }}
          </h2>
          <p class="subtitle">
            {{ $t('ownership.sectionSubtitle') }}
          </p>
        </header>

        <div class="two-column-section__content">
          <h2 class="content-title mb-2 font-size-base font-weight-normal">
            {{ $t('owner') }}
          </h2>

          <div
            v-if="loading"
            class="d-flex flex-column sk-block pa-4 space-y-7">
            <div class="d-flex space-x-4">
              <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
              <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
              <div class="sk-block sk-h-8 sk-dark sk-w-24"/>
            </div>
            <div class="sk-block sk-dark sk-h-6 sk-full-width"/>
          </div>

          <CyOwnerCard
            v-else
            class="mb-2"
            :owner="project.owner"/>
          <div
            v-has-rights-to="['UpdateProject', projectCanonical]"
            class="cy-link"
            @click="$toggle.modals.transferOwnership(true)">
            {{ $t('ownership.edit') }}...
          </div>
        </div>
      </section>

      <v-divider class="my-8"/>

      <section>
        <div
          v-if="loading"
          class="d-flex flex-column sk-block pa-4 space-y-7">
          <div class="d-flex space-x-4">
            <div class="sk-block sk-dark sk-h-12 sk-w-24"/>
            <div class="sk-block sk-dark sk-h-12 sk-full-width"/>
            <div class="sk-block sk-h-12 sk-dark sk-w-24"/>
          </div>
        </div>

        <CyDangerZone
          v-else
          v-has-rights-to="'DeleteProject'"
          class="two-column-section"
          :entity-name="projectName"
          :entity-type="$t('project')"
          :delete-button-text="`${$t('delete.title')}...`"
          :delete-title="$t('delete.title')"
          :delete-text="$t('delete.description')"
          @delete="deleteProject">
          <template #confirmText>
            <p v-html="$sanitizeHtml($t('delete.modalDescriptionHeader', { projectName }))"/>
            <p v-html="$sanitizeHtml($t('delete.modalDescription'))"/>
            <p>{{ $t('forms.areYouSure') }}</p>
          </template>
        </CyDangerZone>
      </section>
    </div>

    <CyModal
      v-if="modals.transferOwnership"
      :header-title="$t('ownership.edit')"
      :loading="saving"
      :action-btn-disabled="!canSaveOwner"
      :action-btn-func="updateProject"
      :cancel-btn-func="onCancelTransferOwnership"
      :action-btn-text="$t('ownership.edit')"
      :close-modal-on-action-click="false"
      :width="555"
      modal-type="warning">
      <p v-text="$t('ownership.editDescription')"/>

      <CySelectOwner
        v-model="owner"
        class="required-field"
        :current-owner="project.owner"/>
    </CyModal>

    <CyModal
      v-if="modals.updateConfigRepository"
      :header-title="$t('configRepository.edit')"
      :loading="saving"
      :action-btn-disabled="!canSaveConfigRepository"
      :action-btn-func="updateProject"
      :action-btn-text="$t('configRepository.edit')"
      :cancel-btn-func="onCancelChangeConfigRepo"
      :close-modal-on-action-click="false"
      :width="555"
      modal-type="warning">
      <p v-text="$t('configRepository.editDescription')"/>

      <CyInputsConfigRepositorySelect
        v-model="configRepositoryCanonical"
        class="required-field mt-8"
        :return-object="false"
        :disabled="!canUpdateProject"
        :readonly="!canUpdateProject"
        :hint="$t('configRepository.editHint')"
        persistent-hint
        item-value="canonical"/>
    </CyModal>

    <portal
      v-if="previewedStack"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: closePreview,
            include: getClickOutsideExceptions,
          }"
          aria-label="Stack preview"
          role="region"
          class="side-panel">
          <CyStackPreview
            :stack="previewedStack"
            :show-select-btn="false"
            @close="closePreview"/>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb, hasOwnerChanged, displayName } from '@/utils/helpers'
import CyCopyBtn from '@/components/copy-btn'
import CyWizardServiceCard from '@/components/wizard/service-card'
import CyConfigRepositoryCard from '@/components/config-repository-card'
import CyOwnerCard from '@/components/owner-card'
import CySelectOwner from '@/components/select-owner'
import CyDangerZone from '@/components/danger-zone'
import CyInputsConfigRepositorySelect from '@/components/inputs/config-repository-select'
import CyStackPreview from '@/components/stack-preview'

export default {
  name: 'CyPageProjectConfigurationGeneral',
  components: {
    CyCopyBtn,
    CyWizardServiceCard,
    CyConfigRepositoryCard,
    CyOwnerCard,
    CySelectOwner,
    CyDangerZone,
    CyInputsConfigRepositorySelect,
    CyStackPreview,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectConfiguration'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return { title: this.$t('routes.projectConfiguration') }
  },
  validations () {
    const { isCreation, isCanonicalUnique, quotasEnabled } = this
    return {
      formData: {
        teamCanonical: { required: requiredIf(() => isCreation && quotasEnabled) },
        description: {},
        name: {
          required,
          alphaNumeric: (val) => _.isEmpty(val) || REGEX.PROJECT_NAME.test(val),
          isUnique: () => !isCreation || isCanonicalUnique,
        },
        configRepositoryCanonical: { required: requiredIf(() => isCreation) },
        owner: {},
      },
    }
  },
  data: () => ({
    saving: false,
    loading: false,
    modals: {
      deleteProject: false,
      updateConfigRepository: false,
      transferOwnership: false,
    },
    configRepositoryCanonical: '',
    owner: {},
    formData: {
      teamCanonical: '',
      description: '',
      name: '',
    },
    previewedStack: null,
  }),
  computed: {
    ...mapState('organization/project', {
      projectErrors: (state) => state.errors,
    }),
    ...mapState('organization/stack', {
      loadingStack: (state) => state.fetchInProgress.stack,
    }),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    ...mapGetters('organization/configRepository', [
      'configRepository',
    ]),
    ...mapGetters('organization/project', [
      'project',
      'projectCanonical',
    ]),
    errors () {
      const { project = [] } = this.projectErrors
      return { project }
    },
    nameErrors () {
      const errors = []
      const { $dirty, required, alphaNumeric } = this.$v.formData.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!alphaNumeric) errors.push(this.$t('forms.fieldNotAlphaNum'))
      return errors
    },
    canonicalErrors () {
      const errors = []
      const { $dirty, isUnique } = this.$v.formData.name
      if (!$dirty) return errors
      if (!isUnique) errors.push(this.$t('projectCanonicalAlreadyExists'))
      return errors
    },
    canSave () {
      return !this.$v.$invalid && this.$hasDataChanged('formData')
    },
    canSaveConfigRepository () {
      return !this.$v.$invalid && this.$hasDataChanged('configRepositoryCanonical')
    },
    canSaveOwner () {
      return !this.$v.$invalid && this.$hasDataChanged('owner')
    },
    canUpdateProject () {
      return this.$cycloid.permissions.canDisplay('UpdateProject', this.projectCanonical)
    },
  },
  async created () {
    this.loading = true
    this.setup()
    await this.GET_STACK({ stackRef: this.project.service_catalog.ref })
    await this.GET_CONFIG_REPOSITORY({ configRepositoryCanonical: this.configRepositoryCanonical })
    this.loading = false
  },
  methods: {
    ...mapActions('organization/project', [
      'UPDATE_PROJECT',
      'DELETE_PROJECT',
    ]),
    ...mapActions('organization/configRepository', [
      'GET_CONFIG_REPOSITORY',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    setup () {
      const { project } = this
      this.configRepositoryCanonical = _.get(project, 'config_repository_canonical', '')
      this.owner = _.get(project, 'owner', {})
      this.formData = {
        teamCanonical: _.get(project, 'team_canonical', ''),
        description: _.get(project, 'description', ''),
        name: _.get(project, 'name', ''),
      }
      this.$setOriginalData()
    },
    async updateProject () {
      this.$toggle.saving(true)

      const { formData: { name, description }, owner, configRepositoryCanonical, project } = this
      const updatedProject = {
        ...project,
        description,
        name,
        owner,
        config_repository_canonical: configRepositoryCanonical,
      }
      const successMessage = this.$t('alerts.success.project.reassigned', {
        projectName: name,
        owner: displayName(owner),
      })

      project.owner && hasOwnerChanged(project, updatedProject)
        ? await this.UPDATE_PROJECT({ project: updatedProject, successMessage })
        : await this.UPDATE_PROJECT({ project: updatedProject })

      if (!_.isEmpty(this.errors.project)) {
        this.SHOW_ALERT({ type: 'error', content: this.errors.project })
      } else if (this.$hasDataChanged('configRepositoryCanonical')) {
        await this.GET_CONFIG_REPOSITORY({ configRepositoryCanonical: this.configRepositoryCanonical })
      }

      this.$toggle.saving(false)
      this.setup()
      this.$toggle.modals.updateConfigRepository(false)
    },
    async deleteProject () {
      await this.DELETE_PROJECT(this.project)
      this.$router.push({ name: 'projects' })
    },
    onCancelChangeConfigRepo () {
      this.$resetData('configRepositoryCanonical')
      this.$toggle.modals.updateConfigRepository(false)
    },
    onCancelTransferOwnership () {
      this.$resetData('owner')
      this.$toggle.modals.transferOwnership(false)
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    closePreview () {
      this.previewedStack = null
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectConfiguration',
        general: {
          sectionTitle: '@:General',
          sectionSubtitle: 'Update your project name and description.',
          descriptionHint: 'What is the purpose of this project? This will appear in the project list and within project views.',
        },
        configRepository: {
          sectionTitle: '@:ConfigRepository',
          sectionSubtitle: 'Move your project configurations file to another repository.',
          edit: 'Change config repository',
          editDescription: 'Move your project configurations file to another repository. This action will delete configuration files stored in the initial config repository and move them to the new one.',
          editHint: 'The repository where to store this project configuration files.',
        },
        ownership: {
          sectionTitle: 'Transfer ownership',
          sectionSubtitle: 'Transfer this project to another member.',
          edit: '@:ownership.sectionTitle',
          editDescription: 'Transfer the ownership of this project to another member. Owner permissions will be transferred immediately after saving.',
        },
        delete: {
          title: 'Delete project',
          description: 'Deleting this project is a permanent action that cannot be undone. Resources created through pipelines will not be deleted automatically.',
          modalDescriptionHeader: 'You are going to delete the project <b>{projectName}</b>.',
          modalDescription: 'Resources created via pipelines will not be deleted automatically. Please ensure you delete these yourself, as any remaining resources will add to your cloud provider costs. Often times pipelines include a <i>destroy</i> job that can be triggered manually.',
        },

      },
      es: {
        title: '@:routes.projectConfiguration',
        general: {
          sectionTitle: '@:General',
          sectionSubtitle: 'Actualice el nombre y la descripción de su proyecto.',
          descriptionHint: '¿Cuál es el propósito de este proyecto? Esto aparecerá en la lista de proyectos y en las vistas de proyectos.',
        },
        configRepository: {
          sectionTitle: '@:ConfigRepository',
          sectionSubtitle: 'Mueva el archivo de configuración de su proyecto a otro repositorio.',
          edit: 'Cambiar el repositorio de configuración',
          editDescription: 'Mueva el archivo de configuración de su proyecto a otro repositorio. Esta acción eliminará los archivos de configuración almacenados en el repositorio de configuración inicial y los moverá al nuevo.',
          editHint: 'El repositorio donde almacenar los archivos de configuración de este proyecto.',
        },
        ownership: {
          sectionTitle: 'Transferir propiedad',
          sectionSubtitle: 'Transfiere este proyecto a otro miembro.',
          edit: '@:ownership.sectionTitle',
          editDescription: 'Transfiera la propiedad de este proyecto a otro miembro. Los permisos del propietario se transferirán inmediatamente después de guardar.',
        },
        delete: {
          title: 'Eliminar projecto',
          description: 'Eliminar este proyecto es una acción permanente que no se puede deshacer. Los recursos creados a través de pipelines no se eliminarán automáticamente.',
          modalDescriptionHeader: 'Está a punto de eliminar el proyecto <b>{projectName}</b>.',
          modalDescription: 'Los recursos creados a través de las pipelines no se eliminarán automáticamente. Asegúrese de eliminarlos usted mismo, ya que cualquier recurso restante aumentará los costos de su proveedor de cloud. A menudo, las pipelines incluyen un trabajo de <i>destrucción</i> que se puede activar manualmente.',
        },
      },
      fr: {
        title: '@:routes.projectConfiguration',
        general: {
          sectionTitle: '@:General',
          sectionSubtitle: 'Modifiez le nom et la description de votre projet.',
          descriptionHint: 'Quel est le but de ce projet ? Celui-ci apparaîtra dans la liste des projets et dans les vues du projet.',
        },
        configRepository: {
          sectionTitle: '@:ConfigRepository',
          sectionSubtitle: 'Déplacez le fichier de configuration de votre projet vers un autre référentiel.',
          edit: 'Changer le repositorio de configuration',
          editDescription: 'Déplacez le fichier de configuration de votre projet vers un autre référentiel. Cette action supprimera les fichiers de configuration stockés dans le référentiel de configuration initial et les déplacera vers le nouveau.',
          editHint: 'Le référentiel où stocker les fichiers de configuration de ce projet.',
        },
        ownership: {
          sectionTitle: 'Transfert de propriété',
          sectionSubtitle: 'Transférez ce projet à un autre membre..',
          edit: '@:ownership.sectionTitle',
          editDescription: `Transférez la propriété de ce projet à un autre membre. Les autorisations du propriétaire seront transférées immédiatement après l'enregistrement.`,
        },
        delete: {
          title: 'Supprimer le projet',
          description: 'La suppression de ce projet est une action permanente et irréversible. Les ressources créées via les pipelines ne seront pas supprimées automatiquement.',
          modalDescriptionHeader: 'Vous êtes sur le point de supprimer ce projet <b>{projectName}</b>.',
          modalDescription: 'Les ressources créées via des pipelines ne seront pas supprimées automatiquement. Assurez-vous de les supprimer vous-même, car les ressources restantes augmenteront les coûts de votre fournisseur de cloud. Souvent, les pipelines incluent une tâche <i>destroy</i> qui peut être déclenchée manuellement.',
        },
      },
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/scss/components/layouts";

.content-title {
  color: get-color("grey", "dark-2");
}

.danger-zone {
  &__title {
    @extend .two-column-section__header;
  }

  &__content {
    @extend .two-column-section__content;

    background-color: get-color("white");

    .delete-section {
      &__title {
        @extend .content__title;
      }

      &__text {
        @extend .content__subtitle;
      }
    }
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 50%);
  }
}
</style>

<template>
  <v-card
    outlined
    class="config-repo-card">
    <v-card-text class="config-repo-card__content">
      <div class="config-repo-card__avatar">
        <CyIconCredential
          v-if="getAvatar"
          size="32"
          :type="getAvatar"/>
      </div>
      <div>
        <div class="config-repo-card__header">
          {{ configRepository.name }}
        </div>
        <div class="config-repo-card__metadata">
          <CyHeaderMetadata
            :label="$tc('projectsUsingConfigRepository', projects.length, { projectsCount: projects.length })"/>
          <CyHeaderMetadata
            :label="configRepository.url"/>
          <CyHeaderMetadata
            :icon="'fa-code-branch'"
            :label="configRepository.branch"/>
          <CyHeaderMetadata
            v-if="configRepository.default"
            icon="verified"
            :label="$t('Default')"/>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyIconCredential from '@/components/icon-credential'
import CyHeaderMetadata from '@/components/header/metadata'

export default {
  name: 'CyConfigRepositoryCard',
  components: {
    CyHeaderMetadata,
    CyIconCredential,
  },
  props: {
    configRepository: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('organization', {
      projects: (state) => state.available.projects,
    }),
    getAvatar () {
      const providers = ['github', 'gitlab', 'bitbucket', 'azure']
      return _.find(providers, (provider) => this.configRepository.url.includes(provider))
    },
  },
  created () {
    if (this.configRepository?.id) {
      this.FETCH_AVAILABLE({
        keyPath: 'projects',
        extraParams: [{ configRepositoryId: this.configRepository?.id }],
      })
    }
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
  },
  i18n: {
    messages: {
      en: {
        projectsUsingConfigRepository: '{projectsCount} project | {projectsCount} projects',
      },
      es: {
        projectsUsingConfigRepository: '{projectsCount} proyecto | {projectsCount} proyectos',
      },
      fr: {
        projectsUsingConfigRepository: '{projectsCount} projet | {projectsCount} projets',
      },
    },
  },
}
</script>

<style lang="scss">
.config-repo-card {
  &__content {
    display: flex;
    gap: $spacer * 4;
  }

  &__avatar .credential-icon {
    margin-right: 0;
  }

  &__header {
    color: get-color("primary");
    font-size: map.get($font-sizes, "lg");
    font-weight: $font-weight-bold;
    line-height: $line-height-sm;
  }

  &__metadata {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
